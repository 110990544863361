import React from "react";
import Achievements from "./Achievements";
import Education from "./Education";
import Experience from "./Experience";
import PersonalInfo from "./PersonalInfo";
import Social from "../Social";
import Skills from "./Skills";

const index = () => {
   return (
      <section className="main-content ">
         <div className="container">
            <div className="row">
               {/* Personal Info Starts */}

               <div className="col-xl-6 col-lg-5 col-12">
                  <div className="row">
                     <div className="col-12">
                        <h3 className="text-uppercase custom-title text-center mb-0 ft-wt-600">
                           personal infos
                        </h3>
                     </div>
                     {/* End .col */}
                     {/* image for mobile menu */}

                     <div className="col-12 text-center">
                        <PersonalInfo />
                     </div>
                     <div className="col-12 text-center">
                        <div className="contact">
                           {/*  Left Side Starts */}
                           <Social />
                        </div>
                     </div>
                     {/* End personal info */}

                     <div className="col-12 mt-1 text-center">
                        <a
                           className="button"
                           href="img/remy-resume.pdf"
                           download
                        >
                           <span className="button-text">Download CV</span>
                           <span className="button-icon fa fa-download"></span>
                        </a>
                     </div>
                     {/* End download button */}
                  </div>
               </div>
               {/*  Personal Info Ends */}

               {/*  Boxes Starts */}
               <div className="col-xl-6 col-lg-7 col-12 mt-5 mt-lg-0">
                  <Achievements />
               </div>
               {/* Achievements Ends */}
            </div>
            {/* End .row */}

            <hr className="separator" />

            {/* Skills Starts */}
            <div className="row">
               <div className="col-12">
                  <h3 className="text-uppercase pb-4 pb-sm-5 mb-3 mb-sm-0 text-center text-sm-center custom-title ft-wt-600">
                     My Skills
                  </h3>
               </div>
               <Skills />
            </div>
            {/* Skills Ends */}

            <hr className="separator mt-1" />

            {/* Experience & Education Starts */}
            <div className="row">
               <div className="col-12">
                  <h3 className="text-uppercase pb-5 mb-0 text-center text-sm-center custom-title ft-wt-600">
                     Experience
                  </h3>
               </div>
               <div className="col-lg-12 m-15px-tb">
                  <div className="resume-box">
                     <Experience />
                  </div>
               </div>
               <div className="col-12">
                  <h3 className="text-uppercase pb-5 mb-0 text-center text-sm-center custom-title ft-wt-600">
                     Education
                  </h3>
               </div>
               <div className="col-lg-12 m-15px-tb">
                  <div className="resume-box">
                     <Education />
                  </div>
               </div>
            </div>
            {/*  Experience & Education Ends */}
         </div>
      </section>
   );
};

export default index;